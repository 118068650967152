import PubSub from 'pubsub-js';

// 回调的函数（单个函数或函数数组）
let callbackFunction = null
// hash值，判断是否为当前页，不是的话清空回调函数
let hash = ''

PubSub.subscribe('langSwitchEvent', () => {
  if (hash !== window.location.hash) {
    callbackFunction = null
  }
  if (typeof callbackFunction === 'function') {
    callbackFunction();
  } else if (Array.isArray(callbackFunction)) {
    callbackFunction.forEach(cb => {
      if (typeof cb === 'function') {
        cb();
      }
    });
  }
});

export function setLanguageChangeCallback(callback) {
  if (typeof callback === 'function' || Array.isArray(callback)) {
    hash = window.location.hash
    callbackFunction = callback;
  } else {
    throw new Error('Callback must be a function or an array of functions');
  }
}